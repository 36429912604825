:root {
  --line-spacing: 26px;
  --red-margin: 40px;
}

/* styling paper */
.paper {
  width: clamp(365px, 100%, 1000px);
  height: fit-content;
  min-height: 800px;
  position: relative;
  margin: 20px auto 10px auto;
  padding: 40px 0;
  /* 50 px for the alternative options */
  background-color: white;
  box-shadow: 0px 0px 5px 0px #888;
}

@media only screen and (max-width: 420px) {
  .paper {
    width: calc(100% - 20px);
    margin: 10px;
    height: fit-content;
    min-height: 100vh;
  }
}

@media only screen and (min-width: 1000px) {
  .paper {
    margin-top: 40px;
  }
}

/* styling red vertical line */

@media only screen and (min-width: 600px) {
  .paper::before {
    content: "";
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    left: var(--red-margin);
    background-color: rgba(255, 0, 0, 0.6);
  }
}

/* styling blue horizontal lines */
.pattern {
  height: 100%;
  min-height: 750px;
  background-image: repeating-linear-gradient(
    white 0px,
    white 25px,
    teal var(--line-spacing)
  );
}

/* styling text content */
.content {
  padding-top: 26px;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 26px;
  font-size: 19px;
  letter-spacing: 1px;
  word-spacing: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .content {
    padding-left: 52px;
    padding-right: 12px;
  }
}

@media only screen and (max-width: 420px) {
  .content {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.header {
  position: absolute;
  /* top: 10px; */
  top: 20px;
  height: fit-content;
  left: 40px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 1rem;
}

.footer {
  position: absolute;
  bottom: 34px;
  left: var(--red-margin);
  height: 50px;
  right: 0;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  font-size: small;
  line-height: var(--line-spacing);
}

@media only screen and (max-width: 600px) {
  .header,
  .footer {
    left: 0;
  }

  .footer {
    bottom: 44px;
  }
}
