@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Pangolin&display=swap");

body {
  background: linear-gradient(#ffffff70, #ffffff80),
    radial-gradient(ellipse, #f6e1ecc0, #f77dbec0),
    url("./images/backgrounds/wood.jpg");
  background-size: cover;
  min-height: 100vh;
}
